import api from '@iso/containers/services/API/api';
import { message } from 'antd';
import React, { createContext, useState } from 'react';
import { v4 as uuid } from 'uuid';
import dateFormat from '../components/utility/dateFormat';
import MomentDateConverte from '../components/utility/momentDateConverte';
import convertFileToBase64 from '../components/utility/convertFile64';
import ApiBuild from '../containers/services/API/apiBuild';

export const contextImportsXmlObj = createContext({});

const ImportXmlContext = ({ children }) => {
  // const api = ApiBuild();

  const [isLoading, setIsLoading] = useState(false);

  const [isGasFileGenerate, setIsGasFileGenerate] = useState(false);
  const [isOilFileGenerate, setIsOilFileGenerate] = useState(false);

  const [isConfirmDataOil, setIsConfirmDataOil] = useState(false);
  const [isConfirmDataGas, setIsConfirmDataGas] = useState(false);

  const [oilDataSaved, setOilDataSaved] = useState(false);
  const [gasDataSaved, setGasDataSaved] = useState(false);

  const [importsObj, setImportsObj] = useState([]);
  const [isGasAndOil, setIsGasAndOil] = useState(false);
  const [isClose, setIsClose] = useState(false);

  const [isLoadingGasPage, setIsLoadingGasPage] = useState(false);
  const [isLoadingOilPage, setIsLoadingOilPage] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const [filesOil, setFilesOil] = useState([]);
  const [filesGas, setFilesGas] = useState([]);

  const [commentData, setCommentData] = useState('');
  const [isCommentSaved, setIsCommentSaved] = useState(false);
  const [isConfirmComment, setIsComfirmComment] = useState(false);

  const [uepName, setUepName] = useState('- - -');
  const [statusProduction, setStatusProduction] = useState('- - -');
  const [dateProduction, setDateProduction] = useState('- - -');
  const [productionId, setProductionId] = useState(null);
  const [statusProductionColor, setStatusProductionColor] = useState('red');
  const [appropriationData, setAppropriationData] = useState([]);
  const [tableDataTotalProduction, setTableDataTotalProduction] =
    useState(null);

  const [isOilProductionSaved, setIsOilProductionSaved] = useState(false);
  const [isGasProductionSaved, setIsGasProductionSaved] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);

  const [tableDataMeasurementsFoundOil, setTableDataMeasurementsFoundOil] =
    useState(null);
  const [tableDataMeasurementsFoundGas, setTableDataMeasurementsFoundGas] =
    useState(null);

  const [bswDataResumeProduction, setBswDataResumeProduction] = useState(null);
  const [tableDataGasBurned, setTableDataGasBurned] = useState(null);

  const [isFrGas, setIsFrGas] = useState(false);
  const [frGas, setFrGas] = useState([]);
  const [isFrOil, setIsFrOil] = useState(false);
  const [frOil, setFrOil] = useState([]);

  const [isButtonDisabledOilToSave, setIsButtonDisabledOilToSave] =
    useState(true);
  const [isButtonDisabledGasToSave, setIsButtonDisabledGasToSave] =
    useState(true);

  const [calculatedErrorBase64, setCalculatedErrorBase64] = useState(null);

  const DefaultProductionValues = () => {
    setUepName('- - -');
    setStatusProduction('- - -');
    setDateProduction('- - -');
    setProductionId(null);
    setStatusProductionColor('red');
    setIsOilProductionSaved(false);
    setIsGasProductionSaved(false);
    setIsCalculated(false);
    setTableDataTotalProduction(null);
    setTableDataMeasurementsFoundOil(null);
    setTableDataMeasurementsFoundGas(null);
    setBswDataResumeProduction(null);
    setTableDataGasBurned(null);
    setIsFrGas(false);
    setFrGas([]);
    setIsFrOil(false);
    setFrOil([]);
    setIsButtonDisabledOilToSave(true);
    setIsButtonDisabledGasToSave(true);
    setFilesOil([]);
    setFilesGas([]);
    setIsCommentSaved(false);
    setCommentData(null);
  };

  const getAllImports = async (isBotafogoInstance, instanceName) => {
    try {
      setIsLoading(true);

      if (isBotafogoInstance) {
        const res = await api.get(`/productions?instance=${instanceName}`);

        return res.data;
      }

      const res = await api.get(`/productions`);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getDataImportById = async (props) => {
    const { idProduction, isBotafogoInstance, instanceName } = props;

    try {
      if (isBotafogoInstance) {
        const res = await api.get(
          `${process.env.REACT_APP_API2_URL}/productions/${idProduction}/total-daily?instance=${instanceName}`,
        );

        return res;
      }

      const res = await api.get(
        `${process.env.REACT_APP_API2_URL}/productions/${idProduction}/total-daily`,
      );

      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const fileFormateToValidate = async (arrFiles) => {
    const arrFilesFormated = await Promise.all(
      arrFiles.map(async (file) => {
        const fileCopy = file;

        const base64 = await convertFileToBase64(fileCopy);

        fileCopy['contentBase64'] = `data:@file/xml;base64,${base64}`;
        fileCopy['fileType'] = file.name.slice(0, 3);
        fileCopy['fileName'] = file.name;

        return fileCopy;
      }),
    );

    return arrFilesFormated;
  };

  const arrValidate = async (props) => {
    const { arrFiles, type, dailyProductionDate } = props;

    try {
      const res = await api.post(
        `${process.env.REACT_APP_API2_URL}/import-measurements/validate`,
        { files: arrFiles },
      );

      if (
        dailyProductionDate !== '- - -' &&
        dailyProductionDate !== MomentDateConverte(res.data.dateProduction)
      ) {
        throw new Error(`Arquivo com Data de produção diferente.`);
      }

      const fileWithDate = arrFiles.map((file) => {
        const fileCopy = file;
        fileCopy[
          'response'
        ] = `Arquivo referente a produção do dia ${MomentDateConverte(
          res.data.dateProduction,
        )}`;
        fileCopy['dateProduction'] = MomentDateConverte(
          res.data.dateProduction,
        );

        return file;
      });

      const objResponse = {
        isError: false,
        uepName: res.data.uepName,
        resData: res.data,
        files: fileWithDate,
        dateProduction: MomentDateConverte(res.data.dateProduction),
      };

      if (type === 'oil') {
        objResponse['frFields'] = res.data.oil.fr.fields;
        objResponse['totalProduction'] = res.data.oil.totalOilProductionM3;
      } else if (type === 'gas') {
        objResponse['frFields'] = res.data.gas.fr.fields;
        objResponse['totalProduction'] = res.data.gas.totalGasProductionM3;
      }

      return objResponse;
    } catch (error) {
      console.log(error, 'error');
      if (error.message) {
        message.error('Alguns arquivos estão inválidos', 5);

        const fileUpdated = arrFiles.map((file) => {
          const fileCopy = file;

          fileCopy['status'] = 'error';
          fileCopy['response'] = error.message;
          fileCopy['tapyError'] = 'dateProduction';
        });

        return {
          isError: true,
          files: fileUpdated,
          messagesArr: [],
          isLogError: false,
        };
      } else if (error.data.message === 'Arquivos duplicados') {
        const fileUpdated = arrFiles.map((file, i) => {
          const fileCopy = file;

          error.data.duplicatedFiles.forEach((fileDuplicated) => {
            if (fileDuplicated.index === i) {
              fileCopy['status'] = 'error';
              fileCopy['response'] = 'Arquivos duplicados';
            }
          });

          return fileCopy;
        });

        return {
          isError: true,
          files: fileUpdated,
          messagesArr: [],
          isLogError: false,
        };
      } else if (
        error.data.message ===
        `Nenhum ponto de medição no(s) arquivo(s) foi encontrado na configuração de cálculo de ${
          type === 'oil' ? 'óleo' : 'gás'
        }.`
      ) {
        const fileUpdated = arrFiles.map((file) => {
          const fileCopy = file;

          fileCopy['status'] = 'error';
          fileCopy[
            'response'
          ] = `Nenhum ponto de medição no(s) arquivo(s) foi encontrado na configuração de cálculo de ${
            type === 'oil' ? 'óleo' : 'gás'
          }.`;

          return fileCopy;
        });

        return {
          isError: true,
          files: fileUpdated,
          messagesArr: [],
          isLogError: false,
        };
      } else if (error.data.message === 'Datas diferentes entre medições') {
        const fileUpdated = arrFiles.map((file, index) => {
          const fileCopy = file;
          error.data.filesWithDifferentDates.forEach((f) => {
            if (f.index === index) {
              fileCopy['status'] = 'error';
              fileCopy['dateProduction'] = MomentDateConverte(
                dateFormat(f.measurementsWithDifferentDates[0].date),
              );
              fileCopy['typeError'] = 'dateProduction';
              fileCopy['response'] = 'Produção com data diferente.';
            }
          });
          return fileCopy;
        });

        return {
          isError: true,
          files: fileUpdated,
          messagesArr: [],
          isLogError: false,
        };
      } else {
        return {
          isError: true,
          files: arrFiles,
          messagesArr: error.data.errors,
          isLogError: true,
        };
      }
    }
  };

  const errorFunction = (props) => {
    const { files, arrMessages, filesWithDifferentDates } = props;

    const arrCopy = files;
    let errosMessage = [];

    if (arrMessages) {
      const filesLogErrorName = arrMessages.map((message) => {
        const fileName = message.split('Arquivo')[1].split('xml')[0];

        const messageErrorObj = {
          fileName: `${fileName.trim()}xml`,
          message: message,
        };

        return messageErrorObj;
      });

      errosMessage = filesLogErrorName;
    }

    arrCopy.forEach((file, i) => {
      const fileCopy = file;

      const isFileLogError = errosMessage.some(
        (f) => f.fileName === fileCopy.fileName,
      );

      if (isFileLogError) {
        fileCopy['status'] = 'error';
        fileCopy['typeError'] = 'logError';
        fileCopy['response'] = 'Arquivo inválido';

        arrCopy.splice(i, 1);
        arrCopy.push(fileCopy);
      }
    });

    if (filesWithDifferentDates) {
      filesWithDifferentDates.forEach((fileError) => {
        const fileCopy = files[fileError.index];
        fileCopy['status'] = 'error';
        fileCopy['typeError'] = 'dateProduction';
        fileCopy['response'] = 'Data de produção diferente';
        fileCopy['dateProduction'] = MomentDateConverte(
          dateFormat(fileError.measurementsWithDifferentDates[0].date),
        );
      });
    }

    return {
      filesArr: arrCopy,
      messagesError: errosMessage,
    };
  };

  const createArrMeasurementsDataResume = (fileValidate, typeFile) => {
    let arrFileMeasurement = [];
    let arrMeasurementsNotFound = [];

    if (typeFile === 'gas') {
      arrFileMeasurement = [...fileValidate._002File, ...fileValidate._003File];
    }

    if (typeFile === 'oil') {
      arrFileMeasurement = [...fileValidate._001File];
    }

    if (fileValidate.measurementsNotFound.length > 0) {
      arrMeasurementsNotFound = fileValidate.measurementsNotFound;
    }

    const arrMeasurement = [];

    arrFileMeasurement.forEach((file) => {
      file.measurements.forEach((measurement) => {
        if (
          !arrMeasurement.some(
            (measur) =>
              measur.summary.tagMeasuringPoint ===
                measurement.summary.tagMeasuringPoint &&
              measurement.summary.volume === measur.summary.volume,
          )
        ) {
        }
        arrMeasurement.push(measurement);
      });
    });

    return {
      arrMeasurements: arrMeasurement,
      arrMeasurementsNotFound: arrMeasurementsNotFound,
    };
  };

  const measurementObjArr = (arrMeasurments) => {
    const arrayData = arrMeasurments.map((elem) => {
      if (!elem.summary) {
        return {
          key: uuid(),
          locationMeasuringPoint: elem.locationMeasuringPoint,
          tagMeasuringPoint: elem.tagMeasuringPoint,
          date: MomentDateConverte(elem.date),
          volume: parseFloat(elem.volume).toFixed(5),
          status: elem.status
            ? 'Encontrado no arquivo'
            : 'Não encontrado no arquivo',
          fileName: '- - -',
          allData: elem,
        };
      }

      return {
        key: uuid(),
        locationMeasuringPoint: elem.summary.locationMeasuringPoint,
        tagMeasuringPoint: elem.summary.tagMeasuringPoint,
        date:
          MomentDateConverte(dateFormat(elem.summary.date)) === 'Data inválida'
            ? MomentDateConverte(elem.summary.date)
            : MomentDateConverte(dateFormat(elem.summary.date)),
        volume: parseFloat(elem.summary.volume).toFixed(5),
        status: elem.summary.status
          ? 'Encontrado no arquivo'
          : 'Não encontrado no arquivo',
        fileName: elem.fileName,
        allData: elem,
      };
    });

    return arrayData;
  };

  const verifyIfThereIsNewFileInArray = (arrFilesGenerated, fileArray) => {
    let isNewFile = false;

    const arr = fileArray.map((file) => {
      if (file.name) {
        return {
          key: uuid(),
          fileType: file.name.slice(0, 3),
          fileName: file.name,
          contentBase64: `data:@file/xml;base64,${file.base64}`,
        };
      }

      if (!file.name) {
        return {
          key: uuid(),
          fileType: file.fileName.slice(0, 3),
          fileName: file.fileName,
          contentBase64: `data:@file/xml;base64,${file.base64}`,
        };
      }
    });

    arr.forEach((file) => {
      if (!arrFilesGenerated.some((fileName) => file.fileName === fileName)) {
        if (file.fileName !== '- - -') {
          isNewFile = true;
        }
      }
    });

    if (arr.length < arrFilesGenerated.length) {
      isNewFile = true;
    }

    const respObj = {
      isNewFile: isNewFile,
      arrFilesFilteredNames: [],
    };

    const namesArr = arr.map((files) => {
      if (files.fileName) {
        return files.fileName;
      }
    });

    respObj['arrFilesFilteredNames'] = namesArr;

    return respObj;
  };

  const ResumeCreateMeasurements = (props) => {
    const { arr, typeFile, statusText } = props;

    const arrChildrenResume = [];
    const resumeDataArchive = [];

    if (typeFile === 'oil') {
      arr.forEach((elem) => {
        if (elem.fileType === '001') {
          if (elem.summary) {
            elem.summary.forEach((element) => {
              const obj = {
                key: uuid(),
                ...element,
                fileName: elem.fileName,
                date: MomentDateConverte(element.dateMeasuring),
                volume: parseFloat(element.volume).toFixed(5),
                status: element.statusMeasuringPoint
                  ? 'Encontrado no arquivo'
                  : 'Não encontrado no arquivo',
              };

              arrChildrenResume.push(obj);
            });
          }

          const obj = {
            key: uuid(),
            type: elem.fileType,
            date: MomentDateConverte(elem.importedAt.split(' ')[0]),
            created_by: elem.importedBy.name,
            status: statusText,
            fileContent: elem.fileContent,
            fileName: elem.fileName,
          };

          resumeDataArchive.push(obj);
        } else if (!elem.fileType && elem.fluid === 'Oil') {
          const obj = {
            key: uuid(),
            ...elem,
            fileName: 'Tags não encontradas',
            date: MomentDateConverte(elem.date),
            volume: parseFloat(elem.volume).toFixed(5),
            status: elem.statusMeasuringPoint
              ? 'Encontrado no arquivo'
              : 'Não encontrado no arquivo',
          };

          arrChildrenResume.push(obj);
        }
      });
    } else if (typeFile === 'gas') {
      arr.forEach((elem) => {
        if (elem.fileType === '003' || elem.fileType === '002') {
          if (elem.summary) {
            elem.summary.forEach((element) => {
              const obj = {
                key: uuid(),
                ...element,
                date: MomentDateConverte(element.dateMeasuring),
                fileName: elem.fileName,
                volume: parseFloat(element.volume).toFixed(5),
                status: element.statusMeasuringPoint
                  ? 'Encontrado no arquivo'
                  : 'Não encontrado no arquivo',
              };

              arrChildrenResume.push(obj);
            });
          }

          const obj = {
            key: uuid(),
            type: elem.fileType,
            date: MomentDateConverte(elem.importedAt.split(' ')[0]),
            created_by: elem.importedBy.name,
            status: statusText,
            fileContent: elem.fileContent,
            fileName: elem.fileName,
          };

          resumeDataArchive.push(obj);
        } else if (!elem.fileType && elem.fluid === 'Gas') {
          const obj = {
            key: uuid(),
            ...elem,
            fileName: 'Tags não encontradas',
            date: MomentDateConverte(elem.date),
            volume: parseFloat(elem.volume).toFixed(5),
            status: elem.statusMeasuringPoint
              ? 'Encontrado no arquivo'
              : 'Não encontrado no arquivo',
          };

          arrChildrenResume.push(obj);
        }
      });
    }

    const fileNotFound = {
      key: uuid(),
      type: 'Tags não encontradas',
      date: resumeDataArchive[0].date ? resumeDataArchive[0].date : '- - -',
      created_by: resumeDataArchive[0].created_by,
      status: '- - -',
      measurementsNotFound: arrChildrenResume.filter(
        (measurements) =>
          measurements.status === 'Não encontrado no arquivo' && measurements,
      ),
    };

    return {
      fileResume: [...resumeDataArchive, fileNotFound],
      measurementsResume: arrChildrenResume,
    };
  };

  const createMeasurementsDataResume = async (props) => {
    const { isFileGenerate, type, validateArrFiles, respDataValidate } = props;

    if (isFileGenerate) {
      try {
        const measurementObj = createArrMeasurementsDataResume(
          respDataValidate,
          type,
        );

        const fileNameArr = validateArrFiles.map((file) => file.fileName);

        const measurementObjResume = [
          ...measurementObj.arrMeasurements,
          ...measurementObj.arrMeasurementsNotFound,
        ];

        return {
          arr: measurementObjArr(measurementObjResume),
          isNewResume: true,
          filesNames: fileNameArr,
        };
      } catch (error) {
        console.log(error);
      }
    }
  };

  const frpUpdateValues = (props) => {
    const { type, frpArr, dataRequest, isFr } = props;

    const data = dataRequest;

    if (type === 'oil') {
      if (data.oil.fr.fields.length > 0) {
        data.oil.fr.isApplicable = isFr;
        data.oil.fr.fields.forEach((field) => {
          const findFiledFr = frpArr.find(
            (fieldFr) => fieldFr.field === field.fieldName,
          );

          if (findFiledFr) {
            field.fluidFr = parseFloat(findFiledFr.valor / 100).toFixed(2);
          }
        });
      }

      return data;
    }

    if (type === 'gas') {
      if (data.gas.fr.fields.length > 0) {
        data.gas.fr.isApplicable = isFr;
        data.gas.fr.fields.forEach((field) => {
          const findFiledFr = frpArr.find(
            (fieldFr) => fieldFr.field === field.fieldName,
          );

          if (findFiledFr) {
            field.fluidFr = parseFloat(findFiledFr.valor / 100).toFixed(2);
          }
        });
      }

      return data;
    }
  };

  const bswUpdateValues = (props) => {
    const { bswData, dataRequest } = props;

    const data = dataRequest;

    data._001File.forEach((file) => {
      file.measurements.forEach((measurement) => {
        const findMeasurement = bswData.find(
          (measurementBsw) =>
            measurementBsw.measurement_point_tag ===
            measurement.summary.tagMeasuringPoint,
        );
        measurement.bswManual =
          parseFloat(findMeasurement.bsw.replace('%', '')).toFixed(2) / 100;
      });
    });

    return data;
  };

  const bswResume = (props) => {
    const { dataOil, resume } = props;

    const bswDataResume = dataOil.oil.measuringPoints.map((measurement) => {
      return {
        key: uuid(),
        measurement_point_location: measurement.localPoint,
        measurement_point_tag: measurement.tagMeasuringPoint,
        date: measurement.dateMeasuring,
        amount: parseFloat(measurement.volumeBeforeBsw).toFixed(5),
        beforeAmount: parseFloat(measurement.volumeBeforeBsw).toFixed(5),
        bsw: `${parseFloat(measurement.bsw * 100).toFixed(2)}%`,
        editable: false,
      };
    });

    return bswDataResume;
  };

  const sendImportOil = async (props) => {
    try {
      setIsLoading(true);
      const { frpoArr, dataImport, bswData, history, isFr, isIdProduction } =
        props;

      const objFrpoParms = {
        type: 'oil',
        frpArr: frpoArr,
        dataRequest: dataImport,
        isFr: isFr,
      };

      const frpoResFunc = frpUpdateValues(objFrpoParms);

      const objBswParms = {
        bswData: bswData,
        dataRequest: frpoResFunc,
      };

      const bswRespFunc = bswUpdateValues(objBswParms);

      const resImport = await api.post(
        `${process.env.REACT_APP_API2_URL}/import-measurements`,
        bswRespFunc,
      );

      if (isIdProduction) {
        history.push(`$productionId}`);
        window.location.reload();
      } else {
        history.replace(`productionCad/${resImport.data.productionId}`);
        window.location.reload();
      }
    } catch (error) {
      message.error(error.data.message, 5);
    } finally {
      setIsLoading(false);
    }
  };

  const sendImportGas = async (props) => {
    try {
      const {
        frpoArr,
        dataImport,
        burnedGasData,
        history,
        isFr,
        isIdProduction,
      } = props;

      const objFrpoParms = {
        type: 'gas',
        frpArr: frpoArr,
        dataRequest: dataImport,
        isFr: isFr,
      };

      console.log('isFr', isFr);

      const frpoResFunc = frpUpdateValues(objFrpoParms);
      const dataToImport = frpoResFunc;

      console.log(' dataToImport', dataToImport);

      burnedGasData.forEach((typeBurned) => {
        if (
          typeBurned.description ===
          'Volume de Queima por Limitação Operacional'
        ) {
          dataToImport.gasSummary.detailedBurnedGas.limitOperacionalBurn =
            typeBurned.value;
        }

        if (
          typeBurned.description ===
          'Volume de Queima Durante Parada Programada'
        ) {
          dataToImport.gasSummary.detailedBurnedGas.scheduledStopBurn =
            typeBurned.value;
        }

        if (typeBurned.description === 'Volume de Gás Ventilado') {
          dataToImport.gasSummary.detailedBurnedGas.ventedGas =
            typeBurned.value;
        }

        if (
          typeBurned.description === 'Volume de Queima para Comissionamento'
        ) {
          dataToImport.gasSummary.detailedBurnedGas.forCommissioningBurn =
            typeBurned.value;
        }

        if (typeBurned.description === 'Volume de Queima para Teste de Poço') {
          dataToImport.gasSummary.detailedBurnedGas.wellTestBurn =
            typeBurned.value;
        }

        if (typeBurned.description === 'Volume de Queima Emergêncial') {
          dataToImport.gasSummary.detailedBurnedGas.emergencialBurn =
            typeBurned.value;
        }

        if (typeBurned.description === 'Volume de Queima - Outros') {
          dataToImport.gasSummary.detailedBurnedGas.othersBurn =
            typeBurned.value;
        }
      });

      const resImport = await api.post(
        `${process.env.REACT_APP_API2_URL}/import-measurements`,
        dataToImport,
      );

      if (isIdProduction) {
        history.push(`${productionId}`);
        window.location.reload();
      } else {
        history.replace(`productionCad/${resImport.data.productionId}`);
        window.location.reload();
      }
    } catch (error) {
      message.error(error.data.message, 5);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadAllFiles = async (idProduction, isBotafogo, instance) => {
    try {
      if (isBotafogo) {
        const res = await api.get(
          `/productions/${idProduction}/files?instance=${instance}`,
        );

        return res.data;
      }

      const res = await api.get(`/productions/${idProduction}/files`);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteProduction = async (idProduction) => {
    try {
      await api.delete(`/productions/${idProduction}`);

      message.success('Produção Diária Excluida com Sucesso', 3);
    } catch (error) {
      message.error(error.error.message, 6);
    } finally {
      const arrFilter = importsObj.filter(
        (elem) => elem.productionId !== idProduction,
      );
      setImportsObj(arrFilter);
    }
  };

  const AppropriationDataObj = (arr) => {
    const fieldProduction = [];
    const wellAppropriations = [];

    arr.forEach((field) => {
      const ObjField = {
        ...field,
        gasLossInFieldM3: parseFloat(field.gasLossInFieldM3).toFixed(5),
        gasLossInFieldSCF: parseFloat(field.gasLossInFieldSCF).toFixed(5),
        gasProductionInFieldM3: parseFloat(
          field.gasProductionInFieldM3,
        ).toFixed(5),
        gasProductionInFieldSCF: parseFloat(
          field.gasProductionInFieldSCF,
        ).toFixed(5),
        oilLossInFieldBBL: parseFloat(field.oilLossInFieldBBL).toFixed(5),
        oilLossInFieldM3: parseFloat(field.oilLossInFieldM3).toFixed(5),
        oilProductionInFieldBBL: parseFloat(
          field.oilProductionInFieldBBL,
        ).toFixed(5),
        oilProductionInFieldM3: parseFloat(
          field.oilProductionInFieldM3,
        ).toFixed(5),
        waterLossInFieldBBL: parseFloat(field.waterLossInFieldBBL).toFixed(5),
        waterLossInFieldM3: parseFloat(field.waterLossInFieldM3).toFixed(5),
        waterProductionInFieldBBL: parseFloat(
          field.waterProductionInFieldBBL,
        ).toFixed(5),
        waterProductionInFieldM3: parseFloat(
          field.waterProductionInFieldM3,
        ).toFixed(5),
        idField: field.fieldProductionId,
        key: uuid(),
      };
      delete ObjField.wellAppropriations;

      field.wellAppropriations.forEach((elem) => {
        const objWell = {
          ...elem,
          wellName: elem.wellName,
          downtime: elem.downtime,
          productionOilInWellM3: parseFloat(elem.productionOilInWellM3).toFixed(
            5,
          ),
          productionGasInWellM3: parseFloat(elem.productionGasInWellM3).toFixed(
            5,
          ),
          productionWaterInWellM3: parseFloat(
            elem.productionWaterInWellM3,
          ).toFixed(5),
          productionOilInWellBBL: parseFloat(
            elem.productionOilInWellBBL,
          ).toFixed(5),
          productionGasInWellSCF: parseFloat(
            elem.productionGasInWellSCF,
          ).toFixed(5),
          productionWaterInWellBBL: parseFloat(
            elem.productionWaterInWellBBL,
          ).toFixed(5),
          productionLostOilM3: parseFloat(elem.productionLostOilM3).toFixed(5),
          productionLostGasM3: parseFloat(elem.productionLostGasM3).toFixed(5),
          productionLostWaterM3: parseFloat(elem.productionLostWaterM3).toFixed(
            5,
          ),
          productionLostOilBBL: parseFloat(elem.productionLostOilBBL).toFixed(
            5,
          ),
          productionLostGasSCF: parseFloat(elem.productionLostGasSCF).toFixed(
            5,
          ),
          productionLostWaterBBL: parseFloat(
            elem.productionLostWaterBBL,
          ).toFixed(5),
          idField: field.fieldProductionId,
          key: uuid(),
        };

        wellAppropriations.push(objWell);
      });

      fieldProduction.push(ObjField);
    });

    return {
      fields: fieldProduction,
      wells: wellAppropriations,
    };
  };

  const CalcDailyProduction = async (productionId) => {
    try {
      setIsLoading(true);
      const { data: dataRes } = await api.post(
        `/production-appropriate/${productionId}/confirm`,
      );

      setTableDataTotalProduction((data) => ({
        ...data,
        total_bbl_water: parseFloat(
          dataRes.waterProduction.totalWaterBBL,
        ).toFixed(5),
        total_m3_water: parseFloat(
          dataRes.waterProduction.totalWaterM3,
        ).toFixed(5),
      }));

      const res = AppropriationDataObj(dataRes.fieldProductions);

      setAppropriationData(res);
      setIsCalculated(true);

      message.success('Produção Calculada com sucesso', 3);
    } catch (error) {
      message.error(error.data.message, 10);
      if (error.data.errors.length > 0) {
        const res = await api.post(`/import-measurements/errors`, {
          errors: error.data.errors,
        });

        setCalculatedErrorBase64(res.data.contentBase64);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const CalcDailyProductionResume = async (productionId) => {
    try {
      setIsLoading(true);
      const { data: dataRes } = await api.post(
        `/production-appropriate/${productionId}/validate`,
      );

      // setTableDataTotalProduction((data) => ({
      //   ...data,
      //   total_bbl_water: parseFloat(
      //     dataRes.waterProduction.totalWaterBBL,
      //   ).toFixed(5),
      //   total_m3_water: parseFloat(
      //     dataRes.waterProduction.totalWaterM3,
      //   ).toFixed(5),
      // }));

      const res = AppropriationDataObj(dataRes.fieldProductions);

      return {
        success: true,
        data: res,
      };

      // setAppropriationData(res);
      // setIsCalculated(true);

      // message.success('Produção Calculada com sucesso', 3);
    } catch (error) {
      message.error(error.data.message, 10);
      if (error.data.errors.length > 0) {
        const res = await api.post(`/import-measurements/errors`, {
          errors: error.data.errors,
        });

        setCalculatedErrorBase64(res.data.contentBase64);
        return {
          success: false,
          data: [],
        };
      }
    } finally {
      setIsLoading(false);
    }
  };

  const CloseProduction = async (history) => {
    try {
      await api.post(`/comments-production/${productionId}`, {
        text: commentData,
      });

      message.success('Produção Fechada com Sucesso', 3);

      history();
      window.location.reload();
    } catch (error) {
      message.error(error.data.message, 5);
    }
  };

  const gasProductionTypesResume = (gasData) => {
    const typeMeasurements = [];

    const burnedGasMeasurementArr = gasData.gasBurnt.measuringPoints.map(
      (measur) => {
        return {
          key: uuid(),
          mensuarement_point: measur.localPoint,
          mesuarement_point_tag: measur.tagMeasuringPoint,
          mesuarement_date: MomentDateConverte(measur.dateMeasuring),
          production: parseFloat(measur.individualProduction).toFixed(5),
          line_name: 'Gás Queimado',
        };
      },
    );

    const fuelGasMeasurementArr = gasData.gasFuel.measuringPoints.map(
      (measur) => {
        return {
          key: uuid(),
          mensuarement_point: measur.localPoint,
          mesuarement_point_tag: measur.tagMeasuringPoint,
          mesuarement_date: MomentDateConverte(measur.dateMeasuring),
          production: parseFloat(measur.individualProduction).toFixed(5),
          line_name: 'Gás Combustível',
        };
      },
    );

    const exportedGasMeasurementArr = gasData.gasExported.measuringPoints.map(
      (measur) => {
        return {
          key: uuid(),
          mensuarement_point: measur.localPoint,
          mesuarement_point_tag: measur.tagMeasuringPoint,
          mesuarement_date: MomentDateConverte(measur.dateMeasuring),
          production: parseFloat(measur.individualProduction).toFixed(5),
          line_name: 'Gás Exportado',
        };
      },
    );

    const importedGasMeasurementArr = gasData.gasImported.measuringPoints.map(
      (measur) => {
        return {
          key: uuid(),
          mensuarement_point: measur.localPoint,
          mesuarement_point_tag: measur.tagMeasuringPoint,
          mesuarement_date: MomentDateConverte(measur.dateMeasuring),
          production: parseFloat(measur.individualProduction).toFixed(5),
          line_name: 'Gás Importado',
        };
      },
    );

    const fatherDataMeasure = [
      {
        key: uuid(),
        mPoints: burnedGasMeasurementArr,
        gas_resume: 'Gás Queimado',
        production: parseFloat(gasData.gasBurnt.totalGasBurnt).toFixed(5),
      },
      {
        key: uuid(),
        mPoints: fuelGasMeasurementArr,
        gas_resume: 'Gás Combustível',
        production: parseFloat(gasData.gasFuel.totalGasFuel).toFixed(5),
      },
      {
        key: uuid(),
        mPoints: exportedGasMeasurementArr,
        gas_resume: 'Gás Exportado',
        production: parseFloat(gasData.gasExported.totalGasExported).toFixed(5),
      },
      {
        key: uuid(),
        mPoints: importedGasMeasurementArr,
        gas_resume: 'Gás Importado',
        production: parseFloat(gasData.gasImported.totalGasImported).toFixed(5),
      },
    ];

    return {
      typeData: fatherDataMeasure,
      burnedDetailData: gasData.detailedBurnedGas,
      measurementsArr: typeMeasurements.concat(
        burnedGasMeasurementArr,
        fuelGasMeasurementArr,
        exportedGasMeasurementArr,
        importedGasMeasurementArr,
      ),
    };
  };

  const dailyProductionData = async (props) => {
    const { idProduction, isBotafogoInstance, instanceName } = props;

    const objResponse = {};

    const objRequestProductionData = {
      idProduction: idProduction,
      isBotafogoInstance: isBotafogoInstance,
      instanceName: instanceName,
    };

    const productionDataRes = await getDataImportById(objRequestProductionData);
    const dataProduction = productionDataRes.data;

    const isOilProduction = dataProduction.dailyProduction.statusOil;
    const isGasProduction = dataProduction.dailyProduction.statusGas;

    objResponse['isOilProduction'] = isOilProduction;
    objResponse['isGasProduction'] = isGasProduction;
    objResponse['productionId'] = dataProduction.productionId;

    if (isOilProduction) {
      const objResumeCreateReq = {
        arr: [...dataProduction.files, ...dataProduction.measurementsNotFound],
        typeFile: 'oil',
        statusText: '',
      };

      const resumeResp = ResumeCreateMeasurements(objResumeCreateReq);

      const objBswResume = {
        dataOil: dataProduction,
        resume: true,
      };

      const bswResumeRes = bswResume(objBswResume);

      const isFr = dataProduction.oil.fr.fields.find(
        (filed) => filed.fluidFr > 0,
      );

      objResponse['measurementResumeOil'] = resumeResp;
      objResponse['bswResume'] = bswResumeRes;
      objResponse['isFrOil'] = isFr ? true : false;
      objResponse['fieldsFrOil'] = dataProduction.oil.fr.fields;
    }

    if (isGasProduction) {
      const objResumeCreateReq = {
        arr: [...dataProduction.files, ...dataProduction.measurementsNotFound],
        typeFile: 'gas',
        statusText: '',
      };

      const resumeResp = ResumeCreateMeasurements(objResumeCreateReq);

      const isFr = dataProduction.gas.fr.fields.find(
        (filed) => filed.fluidFr > 0,
      );

      const tableDataTypes = gasProductionTypesResume(dataProduction.gas);

      objResponse['tableDataTypes'] = tableDataTypes;
      objResponse['measurementResumeGas'] = resumeResp;
      objResponse['isFrGas'] = isFr ? true : false;
      objResponse['fieldsFrGas'] = dataProduction.gas.fr.fields;
    }

    const productionValue = {
      key: uuid(),
      total_sm3_oil_net: parseFloat(
        productionDataRes.data.dailyProduction.totalOilM3,
      ).toFixed(5),
      total_sm3_gas: parseFloat(
        productionDataRes.data.dailyProduction.totalGasM3,
      ).toFixed(5),
      total_m3_water: parseFloat(
        productionDataRes.data.water.totalWaterM3,
      ).toFixed(5),
      total_sbbl_oil_net: parseFloat(
        productionDataRes.data.dailyProduction.totalOilBBL,
      ).toFixed(5),
      total_scf_gas: parseFloat(
        productionDataRes.data.dailyProduction.totalGasSCF,
      ).toFixed(5),
      total_bbl_water: parseFloat(
        productionDataRes.data.water.totalWaterBBL,
      ).toFixed(5),
    };

    if (isGasProduction && isOilProduction) {
      objResponse['isCalculated'] = dataProduction.isCalculated;

      if (dataProduction.isCalculated) {
        const isComment = dataProduction.comment ? true : false;
        objResponse['isComment'] = isComment;

        const appropiatonRes = AppropriationDataObj(
          dataProduction.wellAppropriation.fieldProductions,
        );

        objResponse['appropriationArr'] = appropiatonRes;

        if (isComment) {
          if (
            dataProduction.dailyProduction.statusProduction === 'fechado' ||
            dataProduction.dailyProduction.statusProduction === 'corrigido'
          ) {
            objResponse['isClosedProduction'] = true;
            objResponse['commentText'] = dataProduction.comment.text;
          }
        }
      }
    }

    objResponse['canDetailBurnedGas'] = dataProduction.canDetailGasBurned;
    objResponse['dateProduction'] = dataProduction.dateProduction;
    objResponse['uepName'] = dataProduction.uepName;
    objResponse['tableDataTotalProduction'] = productionValue;
    objResponse['statusProduction'] =
      dataProduction.dailyProduction.statusProduction;

    return objResponse;
  };

  const updateBurnedGas = async (dataBurned, productionId) => {
    try {
      setIsLoadingGasPage(true);
      const dataRequest = {};

      dataBurned.forEach((elem) => {
        if (elem.description === 'Volume de Queima por Limitação Operacional') {
          dataRequest['limitOperacionalBurn'] = parseFloat(elem.value).toFixed(
            5,
          );
        }

        if (elem.description === 'Volume de Queima Durante Parada Programada') {
          dataRequest['scheduledStopBurn'] = parseFloat(elem.value).toFixed(5);
        }

        if (elem.description === 'Volume de Gás Ventilado') {
          dataRequest['forCommissioningBurn'] = parseFloat(elem.value).toFixed(
            5,
          );
        }

        if (elem.description === 'Volume de Queima para Comissionamento') {
          dataRequest['ventedGas'] = parseFloat(elem.value).toFixed(5);
        }

        if (elem.description === 'Volume de Queima para Teste de Poço') {
          dataRequest['wellTestBurn'] = parseFloat(elem.value).toFixed(5);
        }

        if (elem.description === 'Volume de Queima Emergêncial') {
          dataRequest['emergencialBurn'] = parseFloat(elem.value).toFixed(5);
        }

        if (elem.description === 'Volume de Queima - Outros') {
          dataRequest['othersBurn'] = parseFloat(elem.value).toFixed(5);
        }
      });

      await api.patch(`/productions/${productionId}/gasDetailed`, dataRequest);

      message.success(
        'Detalhamento de queima de Gás atualizado com sucesso.',
        3,
      );
      setIsLoadingGasPage(false);
    } catch (error) {
      message.error(error.data.message, 5);
    }
  };

  return (
    <contextImportsXmlObj.Provider
      value={{
        isOilFileGenerate,
        setIsOilFileGenerate,
        isGasFileGenerate,
        setIsGasFileGenerate,
        filesOil,
        setFilesOil,
        filesGas,
        setFilesGas,
        isConfirmDataOil,
        setIsConfirmDataOil,
        dateProduction,
        setDateProduction,
        uepName,
        setUepName,
        isLoadingGasPage,
        setIsLoadingGasPage,
        statusProduction,
        setStatusProduction,
        isLoadingOilPage,
        setIsLoadingOilPage,
        isLoading,
        setIsLoading,
        isButtonDisabledGasToSave,
        setIsButtonDisabledGasToSave,
        isButtonDisabledOilToSave,
        setIsButtonDisabledOilToSave,
        sendImportOil,
        createMeasurementsDataResume,
        verifyIfThereIsNewFileInArray,
        fileFormateToValidate,
        arrValidate,
        errorFunction,
        isClose,
        setIsClose,
        isOilProductionSaved,
        setIsOilProductionSaved,
        tableDataMeasurementsFoundOil,
        setTableDataMeasurementsFoundOil,
        loadingPage,
        setLoadingPage,
        bswDataResumeProduction,
        setBswDataResumeProduction,
        frOil,
        setFrOil,
        isFrOil,
        setIsFrOil,
        isGasFileGenerate,
        setIsGasFileGenerate,
        isConfirmDataGas,
        setIsConfirmDataGas,
        sendImportGas,
        isGasProductionSaved,
        setIsGasProductionSaved,
        tableDataMeasurementsFoundGas,
        setTableDataMeasurementsFoundGas,
        tableDataGasBurned,
        setTableDataGasBurned,
        isFrGas,
        setIsFrGas,
        frGas,
        setFrGas,
        updateBurnedGas,
        productionId,
        setProductionId,
        tableDataTotalProduction,
        setTableDataTotalProduction,
        appropriationData,
        setAppropriationData,
        commentData,
        setCommentData,
        isConfirmComment,
        setIsComfirmComment,
        isCommentSaved,
        setIsCommentSaved,
        importsObj,
        setImportsObj,
        oilDataSaved,
        setOilDataSaved,
        isGasAndOil,
        setIsGasAndOil,
        isCalculated,
        setIsCalculated,
        gasDataSaved,
        setGasDataSaved,
        dailyProductionData,
        getAllImports,
        statusProductionColor,
        setStatusProductionColor,
        CalcDailyProduction,
        CloseProduction,
        downloadAllFiles,
        getDataImportById,
        deleteProduction,
        DefaultProductionValues,
        calculatedErrorBase64,
        setCalculatedErrorBase64,
        CalcDailyProductionResume,
      }}
    >
      {children}
    </contextImportsXmlObj.Provider>
  );
};

export default ImportXmlContext;
